import React from 'react';

// orange cloud
import orangeCloudSmall from './assets/small/orange-cloud.jpg';
import orangeCloudDesktop from './assets/desktop/orange-cloud-desktop.jpg';
import orangeCloudTablet from './assets/tablet/orange-cloud-tablet.jpg';
import orangeCloudPhone from './assets/phone/orange-cloud-phone.jpg';

// purple cloud
import purpleCloudSmall from './assets/small/purple-cloud.jpg';
import purpleCloudDesktop from './assets/desktop/purple-cloud-desktop.jpg';
import purpleCloudTablet from './assets/tablet/purple-cloud-tablet.jpg';
import purpleCloudPhone from './assets/phone/purple-cloud-phone.jpg';

const WallpaperList = () => {
  return (
    <div className='list' >
      <p>/daftar wallpaper</p>
      <div className='row mt-20'>
        <div className='col-6 mt-4'>
          <div>
            <div className='row'>
              <div className='col-3'>
                <img
                  className='img-rounded'
                  src={orangeCloudSmall} />
              </div>
              <div className='col-6 ml-8'>
                <p className='text-black text-sm'>nama: orange cloud</p>
                <p className='text-black text-sm'>pembuat: ragilyudhanto</p>
                <a className='text-blue text-sm mr-4' target={'_blank'} href={orangeCloudDesktop} download={'orange-cloud-desktop'}>desktop</a>
                <a className='text-blue text-sm mr-4' target={'_blank'} href={orangeCloudTablet} download={'orange-cloud-tablet'}>tablet</a>
                <a className='text-blue text-sm mr-4' target={'_blank'} href={orangeCloudPhone} download={'orange-cloud-phone'}>phone</a>
              </div>
            </div>
          </div>
        </div>
        <div className='col-6 mt-4'>
          <div>
            <div className='row'>
              <div className='col-3'>
                <img
                  className='img-rounded'
                  src={purpleCloudSmall} />
              </div>
              <div className='col-6 ml-8'>
                <p className='text-black text-sm'>nama: purple cloud</p>
                <p className='text-black text-sm'>pembuat: ragilyudhanto</p>
                <a className='text-blue text-sm mr-4' target={'_blank'} href={purpleCloudDesktop} download>desktop</a>
                <a className='text-blue text-sm mr-4' target={'_blank'} href={purpleCloudTablet} download>tablet</a>
                <a className='text-blue text-sm mr-4' target={'_blank'} href={purpleCloudPhone} download>phone</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='preview'>

      </div>
    </div>
  )
}

export default WallpaperList