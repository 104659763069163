import React from 'react';
import Typing from 'react-typing-animation';
import button from './button.png';

const Content = () => {
    return (
        <div className="content" >
            <div className="desc">
                <img
                    style={{
                        marginBottom :'10px'
                    }}
                    src={button} />
                <Typing>
                    <p className='terminal-comment'># halo! selamat datang di lookatme!</p>
                    <p className='terminal-comment'># disini kalian boleh download wallpaper secara gratis</p>
                    <p className='terminal-comment'># semua wallpaper disini adalah original buatan saya dan team</p>
                    <p className='terminal-comment'># jadi kalian jangan khawatir tentang lisensinya :)</p>
                    <p className='terminal-light'>personal web = <a href='https://ragilyudhanto.xyz' target={'_blank'} className='terminal-blue a-nondecor'>“ragilyudhanto.xyz"</a></p>
                </Typing>
            </div>
        </div>
    )
}

export default Content