import React from 'react';

const Navbar = () => {
    return (
        <div className="navbar">
            <a href="#">/lookatme!</a>
        </div>
    )
}

export default Navbar