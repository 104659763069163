import "./index.css";

// components
import Navbar from "./navbar";
import Content from "./content";
import Footer from "./footer";
import WallpaperList from "./wallpaper-list";

function App() {
  return (
    <div className="app">
      {/* navbar */}
      <Navbar />
      
      {/* content */}
      <Content />

      {/* content */}
      <WallpaperList />

      {/* footer */}
      <Footer />
    </div>
  );
};

export default App;
